
import React from 'react'
import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'

import { Bars3Icon } from '@heroicons/react/24/outline'
import langIcon from '../assets/images/omniNew/header/lang.svg'
import setupIcon from '../assets/images/mkhHeader/setup.png'

import logoIcon from '../assets/images/omniNew/trade/logo.png'
import listIcon from '../assets/images/omniNew/header/list.svg'
import LangModal from './LangModal'
import Setup from './Setup/Setup'
import { show as setupShow } from '../store/modules/setupStore'
import { show as langModalShow } from '../store/modules/langModalStore'

import { useAccount } from 'wagmi'

import { ConnectButton } from '@rainbow-me/rainbowkit'
import { injected } from 'wagmi/connectors'
import { useConnect } from 'wagmi'
function Header () {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const { address } = useAccount({})
  const { connect } = useConnect()
  const connectMetaMask = () => {
    connect({
      connector: injected(),
      chainId: 56
    })
  }

  // useEffect(() => {
  //   if (!localStorage.getItem('language')) {
  //     localStorage.setItem('language', 'zh_TW')
  //   } else {
  //     if (localStorage.getItem('language') == 'zh_CN') {
  //       i18n.changeLanguage('zh_CN')
  //     } else if (localStorage.getItem('language') == 'en_US') {
  //       i18n.changeLanguage('en_US')
  //     } else if (localStorage.getItem('language') == 'zh_TW') {
  //       i18n.changeLanguage('zh_TW')
  //     } else if (localStorage.getItem('language') == 'ja') {
  //       i18n.changeLanguage('ja')
  //     } else if (localStorage.getItem('language') == 'ko') {
  //       i18n.changeLanguage('ko')
  //     } else if (localStorage.getItem('language') == 'es') {
  //       i18n.changeLanguage('es')
  //     } else if (localStorage.getItem('language') == 'pt') {
  //       i18n.changeLanguage('pt')
  //     } else if (localStorage.getItem('language') == 'de') {
  //       i18n.changeLanguage('de')
  //     } else if (localStorage.getItem('language') == 'fr') {
  //       i18n.changeLanguage('fr')
  //     } else if (localStorage.getItem('language') == 'th') {
  //       i18n.changeLanguage('th')
  //     }
  //   }
  // }, [])

  return (
    <div className=" relative flex justify-between items-center w-screen h-[50px]   z-20 bg-[#000000]">
      {/* <div className=" ml-4 flex justify-end">
        <Setup />
        <div
          className="w-9 h-9 flex justify-center items-center "
          onClick={() => {
            dispatch(setupShow())
          }}>
          <img src={setupIcon} alt="" className="w-7 h-7  mr-3" />
        
        </div>
      </div> */}
      {/*logo */}
      <LangModal></LangModal>
      <Setup />

      <div className="pl-4  flex justify-center items-center">
        <div className='' onClick={() => {
          dispatch(setupShow())
        }}>
          <img src={listIcon} className="w-[24px] h-[24px]" />
        </div>

        <div className='pl-2'>
          <img src={logoIcon} className="w-[24px] h-[24px]" />
        </div>

        {/* <div className='  font-black text-[20px]  text-white ml-2'>SNB</div> */}

      </div>
      <div className='flex '>
        {/* <ConnectButton /> */}
        {address ? <div className=" text-[10.11px] border w-[65px] h-[21px] rounded-full text-[#ffffff] font-bold flex items-center justify-center">
          {address?.replace(/(.{2}).{36}(.{4})/, '$1****$2')}
        </div> : <button className='text-[10.11px] border  px-2 h-[21px] rounded-full'
          onClick={() => {
            connectMetaMask()
          }}
        >Connect Wallet</button>}

        <div
          className=" pl-2  flex items-center justify-end"
          onClick={() => {
            dispatch(langModalShow())
          }}>

          <img src={langIcon} alt="" className="w-[20.4px] h-[20.4px]  mr-3" />
        </div>
      </div>


    </div>
  )
}

export default Header
